<template>
    <div>
        <svg width="100%" height="100%" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <text id="text297146-5" fill="black" xml:space="preserve" style="white-space: pre"
                  font-family="Roboto" font-size="2" font-weight="bold" letter-spacing="0em"><tspan x="0" y="-1">Move Piston</tspan></text>
            <path d="M11.4536 5.36843C11.4536 8.04092 9.06675 10.2062 6.12322 10.2062C3.18165 10.2062 0.794769 8.04092 0.794769 5.36843C0.794769 2.69595 3.18165 0.530655 6.12322 0.530655C9.06675 0.530655 11.4536 2.69595 11.4536 5.36843Z"
                  fill="#00AEFF"/>
            <path d="M9.32264 5.36844C9.32264 6.97157 7.89091 8.27148 6.12323 8.27148C4.35751 8.27148 2.92577 6.97157 2.92577 5.36844C2.92577 3.7653 4.35751 2.46542 6.12323 2.46542C7.89091 2.46542 9.32264 3.7653 9.32264 5.36844Z"
                  fill="#C1C1C6"/>
            <path d="M6.12323 2.58424C7.86747 2.58424 9.28357 3.85222 9.31873 5.42875C9.31873 5.40747 9.32264 5.38794 9.32264 5.36844C9.32264 3.7653 7.89091 2.46542 6.12323 2.46542C4.35751 2.46542 2.92577 3.7653 2.92577 5.36844C2.92577 5.38794 2.92967 5.40747 2.92967 5.42875C2.96483 3.85222 4.38094 2.58424 6.12323 2.58424Z"
                  fill="#F0F0F5"/>
            <path d="M6.12323 8.19699C4.37118 8.19699 2.95117 6.91661 2.92773 5.33121C2.92773 5.34362 2.92577 5.35603 2.92577 5.36844C2.92577 6.97158 4.35751 8.27148 6.12323 8.27148C7.89091 8.27148 9.32264 6.97158 9.32264 5.36844C9.32264 5.35603 9.32067 5.34362 9.32067 5.33121C9.29723 6.91661 7.87724 8.19699 6.12323 8.19699Z"
                  fill="#929296"/>
            <path d="M6.12322 10.0466C3.21095 10.0466 0.843614 7.92387 0.796736 5.28863C0.796736 5.31523 0.794769 5.34183 0.794769 5.36843C0.794769 8.04091 3.18165 10.2062 6.12322 10.2062C9.06675 10.2062 11.4536 8.04091 11.4536 5.36843C11.4536 5.34183 11.4517 5.31523 11.4517 5.28863C11.4028 7.92387 9.03745 10.0466 6.12322 10.0466Z"
                  fill="#142957"/>
            <path d="M6.12322 0.656563C9.04331 0.656563 11.4126 2.78996 11.4517 5.43229C11.4517 5.41101 11.4536 5.38971 11.4536 5.36843C11.4536 2.69595 9.06675 0.530655 6.12322 0.530655C3.18165 0.530655 0.794769 2.69595 0.794769 5.36843C0.794769 5.38971 0.796736 5.41101 0.796736 5.43229C0.833848 2.78996 3.20509 0.656563 6.12322 0.656563Z"
                  fill="#00AEFF"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'MovePiston',
    }
</script>

<style scoped>

</style>