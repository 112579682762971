<template>
    <div class="injector-enlarged-panel-wrapper">
        <div v-for="(item, index) in items" class="img-text-row">
            <component :is="item.icon"  class="svg-icon-component"></component>
            <span style="white-space: pre-line">{{item.description}}</span>
        </div>
    </div>
</template>

<script>
    import Prime from "@/components/injectors/EnlargedPanel/icons/Prime"
    import MovePiston from "@/components/injectors/EnlargedPanel/icons/MovePiston"
    import ManualFillingArrows from "@/components/injectors/EnlargedPanel/icons/ManualFillingArrows"

    export default {
        name: "ManualLoadMode",
        data() {
            return {
                items: [
                    {
                        icon: Prime,
                        description: 'Кнопка "Prime" активирует автоматическую прокачку линий для удаления пузырьков воздуха'
                    },
                    {
                        icon: MovePiston,
                        description: 'Кнопка Move piston активирует ручное управление прямым и обратным ходом поршня. Подсвечивается при нажатии.'
                    },
                    {
                        icon: ManualFillingArrows,
                        description: 'Кнопки управления прямым и обратным ходом поршня при включенном ручном управлении.'
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/components/injector-enlarged-panel-wrappers.scss";


</style>