<template>
    <div>
        <svg width="100%" height="100%" viewBox="0 0 12 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4954 4.8731C11.4173 4.8731 11.3352 4.85183 11.2649 4.80927L6.40331 1.81934L1.5417 4.80927C1.35419 4.92454 1.09832 4.88019 0.969408 4.70817C0.842447 4.53793 0.891262 4.30562 1.08073 4.18857L6.17284 1.05856C6.31152 0.971661 6.49512 0.971661 6.63575 1.05856L11.7278 4.18857C11.9153 4.30562 11.9642 4.53793 11.8372 4.70817C11.7571 4.81458 11.6282 4.8731 11.4954 4.8731Z"
                  fill="#459182"/>
            <path d="M10.0207 10.7767C9.94253 10.7767 9.8605 10.7572 9.79018 10.7128L6.40328 8.6309L3.01635 10.7128C2.82884 10.8299 2.57297 10.7838 2.44405 10.6135C2.31709 10.4415 2.36594 10.2092 2.5554 10.0939L6.17281 7.86833C6.31149 7.78321 6.49509 7.78321 6.63572 7.86833L10.2531 10.0939C10.4406 10.2092 10.4894 10.4415 10.3625 10.6135C10.2824 10.7199 10.1535 10.7767 10.0207 10.7767Z"
                  fill="#459182"/>
            <path d="M2.78588 16.6821C2.65306 16.6821 2.52414 16.6253 2.44405 16.5189C2.31709 16.3469 2.36594 16.1146 2.5554 15.9993L6.17281 13.7737C6.31149 13.6886 6.49509 13.6886 6.63572 13.7737L10.2531 15.9993C10.4406 16.1146 10.4914 16.3469 10.3625 16.5189C10.2355 16.6892 9.97965 16.7335 9.79018 16.6182L6.40328 14.5345L3.01635 16.6182C2.94603 16.6608 2.86596 16.6821 2.78588 16.6821Z"
                  fill="#459182"/>
            <path d="M9.40543 22.2097C9.3273 22.2097 9.2472 22.1884 9.17493 22.1441L6.4033 20.4398L3.63165 22.1441C3.44414 22.2611 3.18631 22.215 3.05935 22.0448C2.93239 21.8745 2.98123 21.6404 3.1707 21.5252L6.17283 19.6791C6.31151 19.5922 6.49511 19.5922 6.63574 19.6791L9.63785 21.5252C9.82536 21.6404 9.87614 21.8745 9.74723 22.0448C9.66714 22.1512 9.53825 22.2097 9.40543 22.2097Z"
                  fill="#459182"/>
            <path d="M3.87385 27.3507C3.77424 27.3507 3.67655 27.3082 3.61795 27.2284C3.52224 27.1007 3.55937 26.9251 3.7 26.8382L6.23141 25.283C6.33493 25.2191 6.47163 25.2191 6.57711 25.283L9.10851 26.8382C9.24915 26.9251 9.28627 27.1007 9.19056 27.2284C9.09485 27.356 8.90148 27.3897 8.76085 27.3028L6.40329 25.854L4.04573 27.3028C3.99299 27.3365 3.93245 27.3507 3.87385 27.3507Z"
                  fill="#459182"/>
            <path d="M3.87383 32.4935C3.80742 32.4935 3.74294 32.4651 3.70192 32.4119C3.63942 32.3268 3.66287 32.2098 3.75858 32.1512L6.28802 30.596C6.35833 30.5534 6.45013 30.5534 6.51849 30.596L9.0499 32.1512C9.14365 32.2098 9.16905 32.3268 9.10459 32.4119C9.04013 32.4971 8.91318 32.5201 8.81942 32.4616L6.40327 30.9773L3.98905 32.4616C3.95389 32.4829 3.9129 32.4935 3.87383 32.4935Z"
                  fill="#459182"/>
            <path d="M6.40331 70.9599C6.32323 70.9599 6.24315 70.9386 6.17284 70.8961L1.08073 67.7643C0.891262 67.649 0.842447 67.4167 0.969408 67.2465C1.09832 67.0744 1.35419 67.0301 1.5417 67.1454L6.40331 70.1353L11.2649 67.1454C11.4544 67.0301 11.7103 67.0744 11.8372 67.2465C11.9642 67.4167 11.9153 67.649 11.7278 67.7643L6.63575 70.8961C6.56544 70.9386 6.48535 70.9599 6.40331 70.9599Z"
                  fill="#459182"/>
            <path d="M6.40328 64.1502C6.3232 64.1502 6.24312 64.1271 6.17281 64.0846L2.5554 61.8607C2.36594 61.7437 2.31709 61.5114 2.44405 61.3411C2.57297 61.1691 2.82884 61.1248 3.01635 61.24L6.40328 63.3238L9.79018 61.24C9.97965 61.1248 10.2355 61.1691 10.3625 61.3411C10.4894 61.5114 10.4406 61.7437 10.2531 61.8607L6.63572 64.0846C6.56541 64.1271 6.48532 64.1502 6.40328 64.1502Z"
                  fill="#459182"/>
            <path d="M6.40328 58.2448C6.3232 58.2448 6.24312 58.2235 6.17281 58.1809L2.5554 55.9554C2.36594 55.8401 2.31709 55.6078 2.44405 55.4358C2.57297 55.2655 2.82884 55.2194 3.01635 55.3365L6.40328 57.4184L9.79018 55.3365C9.97965 55.2212 10.2355 55.2655 10.3625 55.4358C10.4894 55.6078 10.4406 55.8401 10.2531 55.9554L6.63572 58.1809C6.56541 58.2235 6.48532 58.2448 6.40328 58.2448Z"
                  fill="#459182"/>
            <path d="M6.40329 52.3394C6.32321 52.3394 6.24313 52.3181 6.17281 52.2756L3.16872 50.4295C2.9812 50.3125 2.93237 50.0801 3.05933 49.9099C3.1863 49.7379 3.44412 49.6936 3.63163 49.8088L6.40329 51.5148L9.17491 49.8088C9.36438 49.6936 9.62025 49.7379 9.74721 49.9099C9.87613 50.0801 9.82534 50.3125 9.63783 50.4295L6.63573 52.2756C6.56541 52.3181 6.48532 52.3394 6.40329 52.3394Z"
                  fill="#459182"/>
            <path d="M6.40329 46.7196C6.34274 46.7196 6.28219 46.7036 6.23141 46.6717L3.7 45.1147C3.55937 45.0278 3.52224 44.854 3.61795 44.7263C3.71366 44.5968 3.9051 44.5632 4.04573 44.65L6.40329 46.1007L8.76085 44.65C8.90148 44.5632 9.09485 44.5968 9.19056 44.7263C9.28627 44.854 9.24916 45.0278 9.10658 45.1147L6.57711 46.6717C6.52437 46.7036 6.46384 46.7196 6.40329 46.7196Z"
                  fill="#459182"/>
            <path d="M6.40327 41.3905C6.36421 41.3905 6.32318 41.3799 6.28802 41.3586L3.75858 39.8016C3.66287 39.7448 3.63942 39.6278 3.70192 39.5427C3.76638 39.4576 3.8953 39.4345 3.98905 39.493L6.40327 40.9774L8.81942 39.493C8.91318 39.4345 9.04013 39.4558 9.10459 39.5427C9.16905 39.6278 9.14365 39.7448 9.0499 39.8016L6.51849 41.3586C6.48529 41.3799 6.44429 41.3905 6.40327 41.3905Z"
                  fill="#459182"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'ManualFillingArrows'
    }
</script>

<style scoped>

</style>
